import * as React from 'react';
import { Link } from 'gatsby';
import NavbarStyled from './styles/Navbar.styled';

const Navbar = (): JSX.Element => {
  return (
    <NavbarStyled>
      <Link activeClassName="active" to="/">
        Kancelaria
      </Link>
      <Link activeClassName="active" to="/services">
        Zakresu usług
      </Link>
      <Link activeClassName="active" to="/contact">
        Kontakt
      </Link>
    </NavbarStyled>
  );
};

export default Navbar;
