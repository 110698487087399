import * as React from 'react';

import Desc from './styles/Desc.styled';
import LogoStyle from './styles/Logo.styled';

const Logo = (): JSX.Element => {
  return (
    <LogoStyle>
      <Desc>
        <p className="title">Kancelaria Adwokacka Elwira Bębenek</p>
        <small>
          <p>adwokat Elwira Bębenek</p>
          <p>absolwent Wydziału Prawa i Administracji</p>
          <p>Uniwersytetu Gdańskiego</p>
        </small>
      </Desc>
    </LogoStyle>
  );
};

export default Logo;
