import * as React from 'react';
import Contact from './Contact';
import FooterStyle from './styles/Footer.styled';

const Footer = (): JSX.Element => {
  return (
    <FooterStyle>
      <div className="left">
        <p>adwokat Elwira Bębenek</p>
        <Contact />
      </div>
    </FooterStyle>
  );
};

export default Footer;
