import styled from 'styled-components';

const Footer = styled.div`
  margin-top: 0;
  padding: 1rem;
  flex-direction: column;
  display: flex;
  gap: 2rem;
  p {
    margin-bottom: 0;
  }
  @media (min-width: 768px) {
    margin-top: 3rem;
    padding: 3rem 1rem;
    flex-direction: row;
  }
`;

export default Footer;
